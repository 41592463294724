import * as CookieConsent from 'vanilla-cookieconsent';

CookieConsent.run({
  guiOptions: {
    consentModal: {
      layout: 'box wide',
      position: 'bottom right',
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: 'box',
      position: 'right',
      equalWeightButtons: true,
      flipButtons: false,
    },
  },
  categories: {
    necessary: {
      readOnly: true,
    },
    functionality: {},
    analytics: {},
  },
  language: {
    default: 'es',
    autoDetect: 'browser',
    translations: {
      es: {
        consentModal: {
          title: '¡Estimado usuario, tu privacidad nos importa!',
          description: 'Desde el equipo de AESSIA y Binuc ponemos tus derechos y preferencias en el centro de nuestro trabajo. Por favor, lee nuestra política de privacidad para obtener más información sobre cómo y por qué utilizamos tus datos. Si tienes alguna pregunta, no dudes en ponerte en contacto con nosotros.',
          revisionMessage: 'Estimado usuario, hemos realizado modificaciones en la política de cookies desde tu última visita.',
          acceptAllBtn: 'Aceptar todo',
          acceptNecessaryBtn: 'Rechazar todo',
          showPreferencesBtn: 'Gestionar preferencias',
          footer: '<a href="/page/politica-de-privacidad/">Política de privacidad</a>\n',
        },
        preferencesModal: {
          title: 'Preferencias de Consentimiento',
          acceptAllBtn: 'Aceptar todo',
          acceptNecessaryBtn: 'Rechazar todo',
          savePreferencesBtn: 'Guardar preferencias',
          closeIconLabel: 'Cerrar modal',
          serviceCounterLabel: 'Servicios',
          sections: [
            {
              title: 'Uso de Cookies',
              description: 'Esta web utiliza cookies u otros métodos propios y de terceros para prestar sus servicios. Las cookies son ficheros creados en el navegador del usuario que mediante el almacenamiento de datos permiten registrar su actividad en el Plataforma AESSIA y llevan a cabo distintas funcionalidades como el análisis estadístico, una gestión del tráfico web eficiente, la personalización de las herramientas, etc.',
            },
            {
              title: 'Cookies funcionales <span class="pm__badge">Siempre Habilitado</span>',
              description: 'Son aquéllas que son estrictamente necesarias y que permiten al usuario la correcta navegación a través de una página web, plataforma o aplicación, que el contenido de la página web se descarga correctamente y la utilización de las diferentes opciones o servicios que en ella existan, rellenar un formulario o emplear elementos de seguridad, permitiendo la navegación por el sitio web.',
              linkedCategory: 'necessary',
              cookieTable: {
                headers: {
                  name: 'Nombre',
                  domain: 'Servicio',
                  description: 'Descripción',
                  expiration: 'Expiración',
                },
                body: [
                  {
                    name: 'csrftoken',
                    domain: 'Plataforma AESSIA',
                    description: 'Cookie de seguridad para evitar ataques CSRF',
                    expiration: 'Sesión',
                  },
                  {
                    name: 'sessionid',
                    domain: 'Plataforma AESSIA',
                    description: 'Cookie de seguridad para identificación de la sesión del usuario',
                    expiration: 'Sesión',
                  },
                  {
                    name: 'mini-navbar',
                    domain: 'Plataforma AESSIA',
                    description: 'Cookie que almacena la preferencia del usuario de la barra de navegación',
                    expiration: '12 meses',
                  },
                  {
                    name: 'step',
                    domain: 'Plataforma AESSIA',
                    description: 'Cookie que almacena el grado de avance en el proceso de registro',
                    expiration: '12 meses',
                  },
                  {
                    name: 'current-tabs',
                    domain: 'Plataforma AESSIA',
                    description: 'Cookie que almacena la pestaña abierta en cada sección',
                    expiration: '12 meses',
                  },
                ],
              },
            },
            {
              title: 'Cookies de personalización',
              description: 'Son aquéllas que permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario configurando así el sitio web de una manera determinada según las preferencias del usuario, como por ejemplo serian el idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde accede al servicio, etc. .',
              linkedCategory: 'functionality',
            },
            {
              title: 'Cookies Analíticas',
              description: 'Son aquéllas que permiten al responsable de las mismas, mediante la recopilación de datos de la actividad de los usuario, llevar el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas con finalidades estadísticas (como realizar estadísticas del número de visitas, servicios/productos demandados, etc.) que permiten más tarde mejorar la forma en que se ofrecen los servicios, mejorar el funcionamiento y servicio al usuario y elaborar perfiles de los usuarios. El seguimiento se hace de manera agregada y anónima.',
              linkedCategory: 'analytics',
              cookieTable: {
                headers: {
                  name: 'Nombre',
                  domain: 'Servicio',
                  description: 'Descripción',
                  expiration: 'Expiración',
                },
                body: [
                  {
                    name: '_ga',
                    domain: 'Sentry <a target=\'_blank\' class="cc__link" href="https://sentry.io">Sentry.io</a>',
                    description: 'Cookie de Google Analytics que habilita la función de control de visitas únicas. La primera vez que un usuario entre en el sitio web a través de un navegador se instalará esta cookie. Cuando este usuario vuelva a entrar en la web con el mismo navegador, la cookie considerará que es el mismo usuario. Solo en el caso de que el usuario cambie de navegador, se considerará otro usuario.',
                    expiration: '12 meses',
                  },
                  {
                    name: 'sentry-sc',
                    domain: 'Sentry <a target=\'_blank\' class="cc__link" href="https://sentry.io">Sentry.io</a>',
                    description: 'Cookie de Sentry que almacena la sesión del usuario',
                    expiration: '12 meses',
                  },
                  {
                    name: '_hjMinimizedPolls',
                    domain: 'Hotjar <a target=\'_blank\' class="cc__link" href="https://hotjar.com">Hotjar.com</a>',
                    description: 'Cookie de Hotjar para la gestión de encuestas',
                    expiration: '12 meses',
                  },
                  {
                    name: '_hjSessionUser',
                    domain: 'Hotjar <a target=\'_blank\' class="cc__link" href="https://hotjar.com">Hotjar.com</a>',
                    description: 'Cookie de Hotjar que almacena la información del usuario',
                    expiration: '12 meses',
                  },
                  {
                    name: '_hjSession',
                    domain: 'Hotjar <a target=\'_blank\' class="cc__link" href="https://hotjar.com">Hotjar.com</a>',
                    description: 'Cookie de Hotjar que almacena la información de la sesión',
                    expiration: '12 meses',
                  },
                ],
              },
            },
            {
              title: 'Más información',
              description: 'Para más información, por favor, contacte con nosotros  <a target=\'_blank\' class="cc__link" href="https://www.binuc.es">Binuc S.L</a>.',
            },
          ],
        },
      },
    },
  },
});